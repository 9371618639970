import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
import { concat, Subject, Observable, of, Subscription } from 'rxjs';
import jsPDF from 'jspdf';
import { DomSanitizer } from '@angular/platform-browser'
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { Location, formatDate } from '@angular/common';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpService } from 'src/app/shared/http.service';
import { environment } from 'src/environments/environment';
import { AppRegExpressionsConfig } from 'src/app/shared/app.regularexpressions';
//import { ConscentformsDetailsComponent } from '../conscentform-details/conscentforms-details.component';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'app-conscentforms',
  templateUrl: './conscentforms.component.html',
  styleUrls: ['./conscentforms.component.scss']
})
export class ConscentformsComponent implements OnInit {
  public appointmentId: string = '';
  facilityId: any;
  public listTemplates: any[] = [];
  public templateDetails: any[] = [];
  public templateQuestions: any[] = [];
  public loadSpinner?: boolean;
  public showEsignModal = false;
  @Input() bookAppointmentForm: UntypedFormGroup;
  public navigateBackToProviderFlowBoard?: boolean = false;
  notifyText: any;
  navigateBacktoCareJouney: any;
  public previsitDocsSubmited = false;
  public displayPatientInfo: boolean = true;
  public displayInsuranceInfo: boolean = false;
  public displayChiefComplaints: boolean = false;
  public displayROS: boolean = false;
  public displayConsentForms: boolean = false;
  public disableSubmitBtn: boolean = true;
  public displaySuccessScreen: boolean = false;
  public pdfConsentFormViewModal: boolean = false;
  public conscentsubmitredirectModal: boolean = false;
  public exceptionalModal: boolean = false;
  public dtAppointmentDetails: any;
  public conscentFormList: any;

  public conscentForm: UntypedFormGroup;
  //@ViewChild(ConscentformsDetailsComponent) private conscentDetailComp?: ConscentformsDetailsComponent;

  public questList: any[] = [];
  public templateAppendName: any;
  public consentFormName: any;
  public queryString: any;
  public paramsList: any[] = [];
  public certifyCheck: boolean = true;
  public declineCheck: boolean = true;
  public maxAppointmentDate?: Date;
  public minAppointmentDate?: Date;
  public currentDate?: Date;

  @Output() checkConscentForms = new EventEmitter();

  signaturePad!: SignaturePad;
  @ViewChild('canvas')
  canvasEl!: ElementRef;
  signatureImg!: string;
  signatureImgString!: string;

  public radioButtons?: string;
  public errorShownModal?: boolean;
  public oldTemplateName: any;
  public templateFooter: any;
  public isDisabledControl: boolean = false;
  public selectedTemplateAPI: any;
  public minToDate = null;
  public maxToDate = '';
  public selectedCheckboxList: any[] = [];
  public faxLengthChk = 10;


  constructor(private activeRoute: ActivatedRoute, private httpService: HttpService, private router: Router, private formBuilder: UntypedFormBuilder, private _location: Location) {

    this.radioButtons = 'None';
    this.conscentForm = this.formBuilder.group({
      //'certifyCheck': [null, Validators.required],
      'parentGuardianSignature': [null, Validators.required],
      'date': [null, Validators.required]

    });
    //this.conscentForm = this.formBuilder.group({});
    this.bookAppointmentForm = this.formBuilder.group({
      'PURPOSE_OF_VISIT': [null, Validators.required],
      'PATIENT_FIRST_NAME': [null, Validators.required],
      'PATIENT_MIDDLE_NAME': [null],
      'PATIENT_LAST_NAME': [null, Validators.required],
      'DATE_OF_BIRTH': [null, Validators.required],
      'GENDER': [null, Validators.required],
      'MARITAL_STATUS': [null, Validators.required],
      'ADDRESS': [null, Validators.required],
      'CITY': [null, Validators.required],
      'STATE': [null, Validators.required],
      'COUNTRY': [null],
      'ZIPCODE': [null, Validators.compose([Validators.required, Validators.pattern(AppRegExpressionsConfig.pincode)])],
      'PHONE_NUMBER': [null, Validators.compose([Validators.required, Validators.pattern(AppRegExpressionsConfig?.mobile)])],
      // 'EMAIL': [null],
      'DOCTOR': [null, Validators.required],
      'APPOINTMENT_DATE': [null, Validators.required],
      'APPOINTMENT_SLOT': [null, Validators.required],
      'TYPE_OF_SERVICE': [null, Validators.required],
      'TYPE_OF_PATIENT': [null, Validators.required],
      'IS_SELF_PAY': [null, Validators.required],
      'ETHNICITY': [null, Validators.required],
      'RACE': [null, Validators.required],
      'SCHEDULE_TYPE': [null],
      'ENDTIME_SLOT': [null],
      'eligibilityRealtimePayerID': [null],
      'claimPayerId': [null],
      'eligibilityPortalID': [null],
      'idDocument': this.formBuilder?.group({
        documentType: [null],
        documentId: [null],
        issueDate: [null],
        expiryDate: [null],
        identityImageId: [null]
      }),
      'doctor_first_name': [null],
      'doctor_last_name': [null]
    });
    this.activeRoute?.queryParams?.subscribe(
      (params: Params) => {
        if (Object.keys(params).length > 0) {

          if (params && params['previsitId']) {
            const appointmentId = params['previsitId'] || null;
            this.appointmentId = appointmentId;

          }


        }
      }
    );

    this.activeRoute?.params?.subscribe(data => {
      if (data && data['facilityId']) {
        this.facilityId = data['facilityId'] || null;
        sessionStorage?.setItem('headerFacilityId', this.facilityId);
      }
    });

    //this.getAppointmentById(this.appointmentId);

  }

  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
  }


  ngOnInit(): void {
    this.getAppointmentById(this.appointmentId);
    this.minAppointmentDate = this.httpService?.convertDate(new Date());
    this.maxAppointmentDate = this.httpService?.convertDate(new Date());
    this.currentDate = this.httpService?.convertDate(new Date());
  }

  public getListTemplates(appointmentId: any): void {
    this.listTemplates = [];
    let action: any = `template/getAllTemplates`;
    this.loadSpinner = true;


    this.httpService.makeRequestWithAction(action, '')
      .subscribe((data: any) => {
        if (data && data.resultList?.length > 0) {
          this.checkConscentForms?.emit(true);
          this.listTemplates = data.resultList || [];
          this.listTemplates?.forEach((formList: any, index: number) => {
            const listTemplateName = formList.templateName;
            if (this.facilityId == "81") {
              this.templateAppendName = "GoodHealth";
            }
            else if (this.facilityId == "201") {
              this.templateAppendName = "PatientsFirst";
              /* if (listTemplateName === "Permission to Treat Minor Form") { this.templateAppendName = "PatientFirst"; }
               else { this.templateAppendName = "PatientsFirst"; }*/

            }

            if (listTemplateName === "HIPAA Compliance Statement Form") {

              this.oldTemplateName = "HIPAA COMPLIANCE STATEMENT";
            }
            else if (listTemplateName === "Billing Consent Form") {

              this.oldTemplateName = "BillingConsentform";

            }
            else if (listTemplateName === "Permission to Treat Minor Form") {
              this.oldTemplateName = "PermissiontoTreatMinor";

            }
            else if (listTemplateName === "Protected Health Information Release Form") {

              this.oldTemplateName = "PHIconsentform";

            }


            formList.status = '';
            formList.s3Key = '';

            //formList.dob = this.formateISOdateTime(this.dtAppointmentDetails.dob);
            formList.dob = formatDate(this.dtAppointmentDetails.dob, 'dd-MM-yyyy', 'en_US');
            formList.age = this.dtAppointmentDetails.age;
            formList.firstName = this.dtAppointmentDetails.firstName;
            formList.lastName = this.dtAppointmentDetails.lastName;
            formList.patientPrimaryPhone = this.dtAppointmentDetails.patientPrimaryPhone;

            if (formList.apiName === "uploadMinorConsentForm" && formList.age > 18) {
              //console.log(index);
              this.listTemplates = this.listTemplates?.filter((listItem: any) => listItem?.apiName !== 'uploadMinorConsentForm') || [];
              // this.listTemplates.splice(index, 1); //hide minor consent form if  patient age is above 18 years
            }
            if (this.conscentFormList.length !== 0) {
              this.conscentFormList?.forEach((list: any) => {
                if (list?.templateName === listTemplateName || list?.templateName === 'GoodHealth_' + this.oldTemplateName || list?.templateName === 'PatientFirst_' + this.oldTemplateName || list?.templateName === 'PatientsFirst_' + this.oldTemplateName) {
                  let status = list?.status;
                  let s3Key = list?.s3Key;

                  formList.status = status;
                  formList.s3Key = s3Key;
                }
              });
              this.disableSubmitBtn = this.listTemplates?.filter((l: any) => l?.status != 'SIGNED').length > 0 ? true : false;
            }
          });
        }
        else {

        }
        this.loadSpinner = false;
      },
        error => {
          this.loadSpinner = false;

        });
  }



  public getSigningUrl(agreementId: any): void {
    this.loadSpinner = true;
    this.showEsignModal = false;
    let action = `SignForm/getSigningUrl?agreementId=${agreementId}&appointmentId=${this.appointmentId}`;
    sessionStorage.setItem('agreementId', agreementId);
    this.httpService.makeGetRequest(action).subscribe((data: any) => {
      this.loadSpinner = false;
      window.open(data.responseObject, '_self');
    },
      (error: any) => {
        this.loadSpinner = false;
        this.getSignedStatus(this.appointmentId);
      });
  }

  public getSignedStatus(appointmentId: any, agreementIdList?: string): void {
    // this.loadSpinner = true;
    const agreementId = agreementIdList || sessionStorage.getItem('agreementId');
    if (agreementId != null) {
      let action = `SignForm/getSignedStatus?agreementId=` + agreementId + `&appointmentId=` + appointmentId;
      this.httpService.makeGetRequest(action).subscribe((data: any) => {
        // sessionStorage.removeItem('agreementId');
        if (!agreementIdList) {
          this.getListTemplates(appointmentId);
        }
      },
        (error: any) => {
          if (!agreementIdList) {
            this.getListTemplates(appointmentId);
          }
          // sessionStorage.removeItem('agreementId');
          // this.notifyText = error?.message || 'Something went wrong please try again later..!';
          // this.exceptionalModal = true;
        });
    }
    else
      this.getListTemplates(appointmentId);
  }

  saveConsentForms() {

    // location?.replace("https://www.careeco.net/home");
    this.conscentsubmitredirectModal = true;
  }
  navigateToHomePage() {
    const base_url = window.location.origin;
    location?.replace(`${base_url}/${this.facilityId}/patientPortal`);
    // location?.replace("https://www.careeco.net/home");
  }

  naviagetBackToProviderFlowBoard(): void {
    window?.open(`${environment?.providerConnetUrl}hospital/hosp-dashboard`, environment?.windowName);
    window.close();
  }

  public navigateToHomeScreen(homeScreen: any): void {
    if (homeScreen) {
      if (this.navigateBackToProviderFlowBoard) {
        this.naviagetBackToProviderFlowBoard();
        sessionStorage?.removeItem('kioskAppointment')
        return;
      }
      if (this.navigateBacktoCareJouney === true) {
        this._location?.back();
        return;
      }
      // this.router.navigate(['/results-page']);
      // this.router.navigate(['/home']);
      location?.replace("https://www.careeco.net/home");
      sessionStorage.removeItem('selectedProvider');
    }
    else {
      this.previsitDocsSubmited = false;
      this.updatePannelFlags('displaySuccessScreen');
    }
  }

  public updatePannelFlags(tabName: any): void {
    if (this.displayPatientInfo && this.checkValidToContinue()) {
      return;
    }
    this.displayPatientInfo = false;
    this.displayChiefComplaints = false;
    this.displayROS = false;
    this.displayConsentForms = false;
    this.displayInsuranceInfo = false;
    this.displaySuccessScreen = false;
    // this[tabName] = true;
    switch (tabName) {
      case 'displayPatientInfo':
        this.displayPatientInfo = true;
        break;
      case 'displayChiefComplaints':
        this.displayChiefComplaints = true;
        break;
      case 'displayROS':
        this.displayROS = true;
        break;
      case 'displayConsentForms':
        this.displayConsentForms = true;
        break;
      case 'displayInsuranceInfo':
        this.displayInsuranceInfo = true;
        // this.getInsuranceDetails();
        break;
      case 'displaySuccessScreen':
        this.displaySuccessScreen = true;
        break;
    }
  }

  // disbale the cointinue butt if not fill all the details
  public checkValidToContinue(): boolean {
    if (this.bookAppointmentForm?.get('PURPOSE_OF_VISIT')?.value === 'Covid19 Vaccination') {
      if (this.bookAppointmentForm?.get('PATIENT_PRE_EXISTING')?.invalid ||
        this.bookAppointmentForm?.get('COVID_VACCINE_DOSAGE')?.invalid) {
        this.bookAppointmentForm?.markAllAsTouched();
        return true
      }
    }
    if (this.bookAppointmentForm?.get('PURPOSE_OF_VISIT')?.invalid || this.bookAppointmentForm?.get('PATIENT_FIRST_NAME')?.invalid || this.bookAppointmentForm?.get('PATIENT_LAST_NAME')?.invalid || this.bookAppointmentForm?.get('DATE_OF_BIRTH')?.invalid || this.bookAppointmentForm?.get('GENDER')?.invalid || this.bookAppointmentForm?.get('MARITAL_STATUS')?.invalid || this.bookAppointmentForm?.get('ETHNICITY')?.invalid || this.bookAppointmentForm?.get('RACE')?.invalid || this.bookAppointmentForm?.get('ADDRESS')?.invalid || this.bookAppointmentForm?.get('ZIPCODE')?.invalid || this.bookAppointmentForm?.get('PHONE_NUMBER')?.invalid || this.bookAppointmentForm?.get('TYPE_OF_SERVICE')?.invalid || this.bookAppointmentForm?.get('TYPE_OF_PATIENT')?.invalid || this.bookAppointmentForm?.get('DOCTOR')?.invalid || this.bookAppointmentForm?.get('APPOINTMENT_DATE')?.invalid || this.bookAppointmentForm?.get('APPOINTMENT_SLOT')?.invalid) {
      this.bookAppointmentForm?.markAllAsTouched();
      return true;
    }
    // city and State validation
    if (this.bookAppointmentForm?.get("ADDRESS")?.valid && this.bookAppointmentForm?.get("STATE")?.invalid && this.bookAppointmentForm?.get("CITY")?.invalid) {
      this.notifyText = 'Please select the valid Street/Address from google suggested places..!';
      this.exceptionalModal = true;
      this.bookAppointmentForm?.markAllAsTouched();
      return true;
    }
    return false;
  }

  public clearFormgroup(controlsList: any) {

    controlsList.forEach((element: any) => {
      this.conscentForm.removeControl(element);
    });
    console.log("conscentFormontrols", this.conscentForm);
  }

  public openConscentModal(templateDetails: any): void {
    this.clearPad();
    this.questList = [];
    this.conscentForm.reset();

    const controlsList = ['primaryNumber', 'cellNumber', 'fax', 'dob', 'email', 'patientName', 'age', 'relationToPatient', 'certifyCheck', 'contactPerson'];

    this.clearFormgroup(controlsList);



    this.exceptionalModal = true;
    this.templateDetails = templateDetails;
    this.selectedTemplateAPI = templateDetails.apiName;
    console.log(this.templateDetails);
    this.templateQuestions = templateDetails.questions;

    this.questList = [...this.questList, ...Array.from(this.templateQuestions)];
    //console.log(this.questList);
    console.log(this.questList);

    this.questList.forEach((element: any) => {
      //console.log(element);
      //console.log(element.inputFieldName);

      if (element.style === "footer") {
        this.templateFooter = element.description;

      }

      if (element.inputFieldName != null) {
        if (element.isMandatory == true) {
          this.conscentForm.addControl(element.inputFieldName, new UntypedFormControl(null, Validators.required));
        }
        else if (element.inputFieldName != 'parentGuardianSignature' && element.inputFieldName != 'date') {
          if (element.inputFieldName == 'email') {
            this.conscentForm.addControl(element.inputFieldName, new UntypedFormControl(null, Validators.compose([null, Validators.pattern(AppRegExpressionsConfig.email)])));
          }
          else if (element.inputFieldName == 'primaryNumber' || element.inputFieldName == 'cellNumber' || element.inputFieldName == 'fax') {
            this.conscentForm.addControl(element.inputFieldName, new UntypedFormControl(null, Validators.compose([null, Validators.pattern(AppRegExpressionsConfig.mobile)])));
          }
          else {
            this.conscentForm.addControl(element.inputFieldName, new UntypedFormControl(null));
          }
        }

        if (element.inputFieldName == 'patientName') {
          this.conscentForm?.get('patientName')?.setValue(this.dtAppointmentDetails.firstName + " " + this.dtAppointmentDetails.lastName);
          //this.conscentForm?.get('patientName')?.disable();
        }

        if (element.inputFieldName == 'dob') {

          /*let patientDob = this.httpService?.convertDate(this.dtAppointmentDetails.dob);
      this.conscentForm?.get('dob')?.setValue(patientDob);*/
          const dobSplit = this.dtAppointmentDetails.dob?.split('T');
          //let patientDob = formatDate(dobSplit[0], 'dd-MM-yyyy', 'en_US');
          this.conscentForm?.get('dob')?.setValue(dobSplit[0]);
          // this.conscentForm?.get('dob')?.disable();
        }
        if (element.inputFieldName == 'age') {
          let patientAge = this.calculateAge(this.dtAppointmentDetails.dob);
          this.conscentForm?.get('age')?.setValue(this.dtAppointmentDetails.age);
          // this.conscentForm?.get('age')?.disable();
          // this.conscentForm?.get('age')?.setValue(patientAge);
        }
        if (element.inputFieldName == 'date') {

          let currentdate = formatDate(new Date(), 'yyyy-MM-dd', 'en_US');
          this.conscentForm?.get('date')?.setValue(currentdate);
        }
        if (element.inputFieldName === 'primaryNumber') {
          let primaryPhone = this.dtAppointmentDetails.patientPrimaryPhone;
          let patientPrimaryPhone = primaryPhone.replace("+1", "");


          this.conscentForm?.get('primaryNumber')?.setValue(patientPrimaryPhone);
          // this.conscentForm?.get('primaryNumber')?.disable();
        }

        if (element.inputFieldName === 'relationToPatient') {
          let relationToPatient = element.options[0];
          //console.log(relationToPatient);
          this.conscentForm?.get('relationToPatient')?.setValue(relationToPatient);
        }
        if (element.inputFieldName === 'certifyCheck') {
          let certifyCheck = element.options[0];
          this.conscentForm?.get('certifyCheck')?.setValue(certifyCheck);
        }



      }
    });
    // console.log(this.conscentForm);

    // console.log(this.templateDetails);
  }

  public submitConscentForm(templateData: any): void {

    // console.log(this.conscentForm);
    if (this.conscentForm?.invalid) {
      this.conscentForm?.markAllAsTouched();
      return;
    }
    // if (this.facilityId == "81") {
    //   this.consentFormName = "GoodHealth_" + templateData.templateName;
    // }
    // else if (this.facilityId == "201") {
    //   this.consentFormName = "PatientFirst_" + templateData.templateName;
    // }

    this.consentFormName = templateData.templateName;

    const params = this.conscentForm.value;
    //console.log("params",params);

    if (params.date) {
      // params.date = formatDate(params?.date, 'MM/dd/yyyy', 'en_US');
      params.date = formatDate(new Date(), 'MM/dd/yyyy', 'en_US');
    }
    if (params.dob) {

      params.dob = formatDate(params?.dob, 'MM/dd/yyyy', 'en_US');
    }


    if (params.primaryNumber) {
      params.primaryNumber = '+1' + params.primaryNumber;
    }

    if (params.certifyCheck) {
      if (params.certifyCheck == 'I decline.') {

        params.certifyCheck = 'decline';
      }
      else {
        params.certifyCheck = 'certify';
      }
    }

    if (params.informationRequested) {
      params.informationRequested = this.selectedCheckboxList;
    }
    else {
      params.informationRequested = null;
    }


    params.appointmentId = this.appointmentId;
    params.facilityID = this.facilityId;
    params.consentFormName = this.consentFormName;

    //console.log(params); 
    // return;
    const size = Object.keys(params).length;

    if (size > 0) {
      this.queryString = '&' + new URLSearchParams(params).toString();

    }
    else {
      this.queryString = '';
    }
    let resourceName = templateData.apiName;
    //let action = 'pdf/' + resourceName + '?appointmentId=' + this.appointmentId + '&facilityID=' + this.facilityId + '&consentFormName=' + this.consentFormName + this.queryString;
    let action = 'pdf/' + resourceName;
    /*console.log(action);
     return;*/

    this.loadSpinner = true;

    console.log(params);

    this.httpService.makeRequestWithAction(action, params)
      .subscribe((data: any) => {
        if (data?.status == 'SUCCESS') {
          //this.errorShownModal = true;
          //this.notifyText = data?.msg || data?.message;
        } else {
          this.errorShownModal = true;
          this.notifyText = data?.msg || data?.message || 'Something went wrong. Please try again later.'
        }
        this.loadSpinner = false;
        this.exceptionalModal = false;
        this.conscentForm?.reset();
        this.getAppointmentById(this.appointmentId);
      },
        error => {
          this.exceptionalModal = false;
          this.loadSpinner = false;
          this.errorShownModal = true;
          this.notifyText = error?.msg || error?.message || 'Something went wrong. Please try again later.'
          this.conscentForm?.reset();
        });

  }

  public clearData() {
    this.conscentForm?.reset();
    this.exceptionalModal = false;
    this.pdfConsentFormViewModal = false;
  }

  public getAppointmentById(appointmentId: string): void {
    if (this.appointmentId != '0') {
      let action: any = `appointment/registration/getAppointmentById?id=` + appointmentId;
      this.loadSpinner = true;
      this.httpService.makeGetRequest(action)
        .subscribe((data: any) => {
          if (data.status == 'SUCCESS') {
            this.dtAppointmentDetails = data.responseObject || [];
            this.conscentFormList = this.dtAppointmentDetails.consentForms;
            // this.conscentFormList.dob = this.httpService?.convertDate(this.dtAppointmentDetails.dob);
            const dobSplit = this.dtAppointmentDetails.dob?.split('T');
            this.conscentFormList.dob = dobSplit[0];


            this.conscentFormList.age = this.dtAppointmentDetails.age;
            this.conscentFormList.firstName = this.dtAppointmentDetails.firstName;
            this.conscentFormList.lastName = this.dtAppointmentDetails.lastName;
            this.conscentFormList.patientPrimaryPhone = this.dtAppointmentDetails.patientPrimaryPhone;
            //console.log(this.conscentFormList);
            //this.getProviderConfig(this.dtAppointmentDetails?.appointment?.purposeOfVisit, this.dtAppointmentDetails.facilityId, appointmentId);
          } else {
            this.notifyText = data?.message || 'Something went wrong please try again later..!';
            this.exceptionalModal = true;
          }
          this.loadSpinner = false;
          this.getListTemplates(this.appointmentId);



        }, error => {
          this.getListTemplates(this.appointmentId);
          this.loadSpinner = false;
          //this.notifyText = error?.message || 'Something went wrong please try again later..!';
          //this.exceptionalModal = true;
        });
    }
  }

  public iAgreecertifyCheck(event: any): void {
    if (event.checked) {
      this.certifyCheck = false;
      this.declineCheck = true;

    } else {
      this.certifyCheck = true;
      this.declineCheck = false;
    }
  }

  public iAgreedeclineCheck(event: any): void {
    if (event.checked) {
      this.declineCheck = false;
      this.certifyCheck = true;

    } else {
      this.declineCheck = true;
      this.certifyCheck = false;
    }
  }

  public calculateAge(birthday: any): any {
    const date = new Date(birthday);
    var ageDifMs = Date.now() - date.getTime();
    var ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  public addYears(date: any, years: any) {
    // console.log("date",date);
    const dtArr = date.split("-");
    const year = dtArr[0];
    const Month = dtArr[1];
    const Day = dtArr[2];
    const Yrchnaged = parseInt(year) + 2;
    const newDate = Yrchnaged + "-" + Month + "-" + Day;
    return newDate;


    // date.setFullYear(date.getFullYear() + years);
    // return date;

    //return new Date(date.setFullYear(date.getFullYear() + years));


  }


  public firechange(event: any): void {
    // let fieldname = event.target.attributes.getNamedItem('ng-reflect-name').value;

    let fieldname = event.target.id;

    if (fieldname == 'dob') {
      let fieldValue = event.target.value;
      let agewithDOB = this.calculateAge(fieldValue);
      this.conscentForm?.get('age')?.setValue(agewithDOB);
    }
    if (fieldname == 'fromDate') {
      const fromDate = this.conscentForm?.get('fromDate')?.value;
      this.minToDate = fromDate;
      this.maxToDate = this.addYears(fromDate, 2);
      //console.log("maxdate",this.maxToDate);

      //this.conscentForm?.get('toDate')?.setValue(fromDate);
    }

    if (fieldname == 'toDate') {
      /*const fromDate = new Date(this.conscentForm?.get('fromDate')?.value);
      const toDate = new Date(this.conscentForm?.get('toDate')?.value);
      if(toDate < fromDate){
      alert("to date is less than from date");
      }*/

    }
  }

  public checkKeyEnter(event: any) {
    console.log(event);
    let fieldname = event.target.attributes.getNamedItem('ng-reflect-name').value;
    console.log(fieldname);
    if (fieldname === 'fromDate' || fieldname === 'toDate') {
      return false;
    }
    else {
      return true;
    }
  }
  public createSelectedList(list: any, event: any): void {
    const selectedList = [];
    for (let index = 0; index < list.length; index++) {

      if (list[index] === event.target.value && event.target.checked) {
        this.selectedCheckboxList?.push(event.target.value);

      }
    }
    console.log(this.selectedCheckboxList);
  }





  startDrawing(event: Event) {
    console.log(event);
    // works in device not in browser

  }

  moved(event: Event) {
    // works in device not in browser
  }

  clearPad() {
    this.signaturePad.clear();
    this.signatureImg = '';
    this.signatureImgString = '';
    this.conscentForm?.get('parentGuardianSignature')?.setValue('');


  }

  public savePad() {
    //console.log(this.signaturePad.isEmpty());
    if (this.signaturePad.isEmpty()) {
      this.conscentForm?.markAllAsTouched();
      return;
    }
    const base64Data = this.signaturePad.toDataURL();
    this.signatureImg = base64Data;
    this.signatureImgString = "Signature saved"
    this.conscentForm?.get('parentGuardianSignature')?.setValue(this.signatureImg);
    if (this.conscentForm?.invalid) {
      this.conscentForm?.markAllAsTouched();
      return;
    } 
    setTimeout(() => {
     // console.log(11);
      this.submitConscentForm(this.templateDetails);
    }, 1500);

  }

  public downloadConsentForm(form: any) {
    //console.log(form);
    const documentType = "Consent Form";
    const action = `record/listIndividualDocument?documentType=${documentType}&appointmentId=${this.appointmentId}&additionalKey=${form?.s3Key}`;
    this.loadSpinner = true;
    this.httpService?.makeGetRequest(action)?.subscribe((data: any) => {
      this.loadSpinner = false;

      if (data?.status === 'SUCCESS') {
        if (data?.responseObject == null || data?.responseObject == '') {

        }
        else {
          const s3Key = data?.responseObject?.s3Key;
          const s3Path = data?.responseObject?.s3Path;
          const fileType = data?.responseObject?.fileType;
          this.downloadIdFile(s3Key, s3Path, fileType);
        }
      }
      // this.dtTriggerAppointmentDocs?.next();
    }, (error) => {

    });



    /* let action: string = '';
     if (form?.s3Key) {
       action = `SignForm/getSignedConsentForm?consentFormKey=${form?.s3Key}`;
     } else {
       // action = `SignForm/getSignedDoc?agreementId=${form?.agreementId}`;
       return;
     }
     //this.cardReload?.cardRefreshShow();
     this.loadSpinner = true;
     this.httpService?.downloadImage(action).subscribe((data: any) => {
       var file = new Blob([data], { type: 'application/pdf' })
       var fileURL = URL.createObjectURL(file);
       const iframeEle = document.getElementById('consent-form-view-pdf') as HTMLElement;
       iframeEle.setAttribute('src', fileURL);
       this.pdfConsentFormViewModal = true;
       // this.cardReload?.cardRefreshHide();
       this.loadSpinner = false;
     },
       (error: any) => {
         this.notifyText = error?.message || 'User does not have privileges to perform action';
         this.pdfConsentFormViewModal = true;
         this.loadSpinner = false;
         // this.cardReload?.cardRefreshHide();
       });*/
  }

  public downloadIdFile(s3Key: any, s3Path: any, fileType: any) {
    const downloadaction = `record/downloadDocument?s3Key=${s3Key}&s3Path=${s3Path}`;
    this.httpService.downloadImage(downloadaction).subscribe((dData: any) => {
      var file = new Blob([dData], { type: fileType })
      var fileURL = URL.createObjectURL(file);

      const iframeEle = document.getElementById('consent-form-view-pdf') as HTMLElement;
      iframeEle.setAttribute('src', fileURL);
      this.pdfConsentFormViewModal = true;

    }, (error: any) => {

    });
  }

  /*public formateISOdateTime(date: any): any {
   const currentLocalDate = new Date().toLocaleDateString(undefined, {
     year: 'numeric',
     month: '2-digit',
     day: '2-digit'
   });
   const currentTime = new Date().toLocaleTimeString('en-GB', {
     hour: '2-digit',
     minute: '2-digit'
   });
   const splitDate = currentLocalDate.split('-');
   const dateConvert = `${splitDate[2]}-${splitDate[0]}-${splitDate[1]}`;
   return `${dateConvert}T${currentTime}`;
 }*/

}